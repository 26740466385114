// tslint:disable-next-line:max-line-length
import { Component, Inject, ComponentFactoryResolver, ViewChild, AfterViewInit, OnDestroy, ComponentRef, ChangeDetectorRef, InjectionToken, Injector } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ExtendableInjector } from '../../extendable-injector';
import { DialogInternalSettings, DialogVisualSettings } from './dialog-settings';
import { Subject } from 'rxjs';
import { DialogRef } from './dialog-ref';
import { InsertionDirective } from '../../directives';

export const DIALOG_DATA: InjectionToken<any> = new InjectionToken('MaDialogData');

@Component({
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements AfterViewInit, OnDestroy {
    @ViewChild(InsertionDirective, { static: true })
    insertionPoint: InsertionDirective;

    private componentRef: ComponentRef<any>;

    beforeAdd: Subject<boolean> = new Subject<boolean>();
    isAddEnabled = true;
    customCloseFn: () => void = null;
    customYesFn: () => void = null;

    constructor(
        public dialogRef: MatDialogRef<DialogComponent>,
        @Inject(MAT_DIALOG_DATA) public settings: DialogInternalSettings,
        private componentFactoryResolver: ComponentFactoryResolver,
        private cd: ChangeDetectorRef,
        private injector: Injector
    ) {}

    close(result: any = false) {
        this.dialogRef.close(result);
    }

    ngOnDestroy(): void {
        if (this.componentRef) {
            this.componentRef.destroy();
        }
    }

    ngAfterViewInit(): void {
        this.initChildComponent();
        this.cd.detectChanges();
    }

    onYesClick() {
        if (this.customYesFn) {
            this.customYesFn();
        }
    }

    onCancelClick() {
        if (this.customCloseFn) {
            this.customCloseFn();
        } else {
            this.close();
        }
    }

    updateSettings(settings: Partial<DialogVisualSettings>) {
        this.settings = Object.assign({}, this.settings, settings);
    }

    private initChildComponent() {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.settings.childComponent);

        const viewContainerRef = this.insertionPoint.viewContainerRef;
        viewContainerRef.clear();

        const dialogRef = new DialogRef(this, this.dialogRef);

        const map = new WeakMap();
        map.set(DIALOG_DATA, this.settings.data);
        map.set(DialogRef, dialogRef);

        const injector = new ExtendableInjector(this.injector, map);
        this.componentRef = viewContainerRef.createComponent(componentFactory, null, injector);
    }
}
