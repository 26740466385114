import { Component, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { CompositeFilterDescriptor, distinct, filterBy } from '@progress/kendo-data-query';
import { FilterService } from '@progress/kendo-angular-grid';

@Component({
  selector: 'multicheck-filter',
  templateUrl: './multicheck-filter.component.html',
  styleUrls: ['./multicheck-filter.component.scss']
})
export class MultiCheckFilterComponent implements AfterViewInit {
  @Input() public isPrimitive: boolean;
  @Input() public currentFilter: CompositeFilterDescriptor;
  @Input() public data;
  @Input() public textField;
  @Input() public valueField;
  @Input() public cssClassField;
  @Input() public filterService: FilterService;
  @Input() public field: string;
  @Input() public showFilter: boolean;
  @Output() public valueChange = new EventEmitter<number[]>();

  public currentData: any;
  private value: any[] = [];

  protected textAccessor = (dataItem: any) => this.isPrimitive ? dataItem : dataItem[this.textField];
  protected valueAccessor = (dataItem: any) => this.isPrimitive ? dataItem : dataItem[this.valueField];

  public ngAfterViewInit(): void {
    this.currentData = this.data;
  }

  public isItemSelected(item): boolean {
    const clone = JSON.parse(JSON.stringify(this.currentFilter.filters));
    let isExist = false;

    try {
      isExist = clone.length
        && !!clone.find(y => y.field === this.field && y.value === item[this.valueField]);
    } catch (e) {
      console.error(e);
    }

    return isExist || this.value.some(x => x === this.valueAccessor(item));
  }

  public onSelectionChange(item): void {
    const clone = JSON.parse(JSON.stringify(this.currentFilter.filters));
    let isExist = false;

    try {
      isExist = clone.length
        && !!clone.find(y => y.field === this.field && y.value === item);
    } catch (e) {
        console.error(e);
    }

    this.value = isExist
      ? clone.filter(y => y.field === this.field && y.value !== item && y.operator === 'eq').map(y => y.value)
      : clone.filter(y => y.operator === 'eq').map(y => y.value);

    if (!isExist) {
      this.value.push(item);
    }
    
    this.filterService.filter({
        filters: this.value.map(value => ({
            field: this.field,
            operator: 'eq',
            value
        })),
        logic: 'or'
    });
  }

  public onInput(e: any): void {

    this.currentData = distinct([
      ...this.currentData.filter(dataItem => this.value.some(val => val === this.valueAccessor(dataItem))),
      ...filterBy(this.data, {
        operator: 'contains',
        field: this.textField,
        value: e.target.value,
      })],
          this.textField
      );
  }
}
