import { Component, Output, EventEmitter, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IGridColumn } from 'src/app/models/grid';
import { GridCommunicationService } from 'src/app/services/grid';

@Component({
  selector: 'columns-selector',
  templateUrl: './columns-selector.component.html',
  styleUrls: ['./columns-selector.component.scss']
})
export class ColumnsSelectorComponent implements OnInit, OnDestroy {
  @Output() closeColumnsSelector = new EventEmitter<boolean>();

  private ngUnsubscribe = new Subject();
  gridColumns: IGridColumn[] = [];
  selectorColumns: IGridColumn[] = [];

  constructor(
    private readonly gridService: GridCommunicationService,
    private readonly cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.gridService.columnsInitiated
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((columns) => {
        this.gridColumns = columns;
        this.selectorColumns = columns
          .filter(column => column.hideable)
          .sort((column1, column2) => column1.selectorOrderIndex - column2.selectorOrderIndex);
        this.cdr.detectChanges();
      });
    this.gridService.columnsChanged
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((columns) => {
        this.gridColumns = columns;
        this.selectorColumns = columns
          .filter(column => column.hideable)
          .sort((column1, column2) => column1.selectorOrderIndex - column2.selectorOrderIndex);
        this.cdr.detectChanges();
      });
  }

  columnsChanged(): void {
    this.gridService.columnsChanged.next(this.gridColumns);
  }

  close(): void {
    this.closeColumnsSelector.emit(false);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
