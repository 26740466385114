import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { of } from "rxjs";
import { ConductReviewService } from "../../services/api";
import { IReviewDetails, ReviewDetails } from "../../models/review/review-details.model";

@Injectable({ providedIn: "root" })
export class ReviewInfoResolver implements Resolve<IReviewDetails> {
    constructor(
        private reviewApiSvc: ConductReviewService) { }
        
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): ReviewDetails | Observable<ReviewDetails> | Promise<ReviewDetails> {
        const reviewId = route.params.reviewId;
        return this.reviewApiSvc.getReviewDetails(reviewId).pipe(map(this.mapReviewDetails.bind(this)));
    }

    private mapReviewDetails(likeReviewDetails: IReviewDetails) {
        return likeReviewDetails !== null 
            ? new ReviewDetails(likeReviewDetails) 
            : of<ReviewDetails>(null);
    }
}