import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateTimeService {
  get now(): Date {
    return new Date(Date.now());
  }

  getDateDiffInHours(start: Date, end: Date): number {
    const startDate = this.convertToDate(start);
    const endDate = this.convertToDate(end);

    return Math.floor(Math.abs(endDate.getTime() - startDate.getTime()) / 36e5);
  }

  public isIsoDate(isoDate: any): boolean {
    const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z?$/;

    if (isoDate === null ||
      isoDate === undefined ||
      typeof isoDate !== "string") {
          return false;
    }

    return isoDateRegex.test(isoDate);
  }

  public convertToDate(date: any): Date {    
    return date instanceof Date
      ? date
      /* Remove Z to prevent convertation from UTC to browser time zone */
      : new Date(date.toString().replace("Z", ""));
  }
}
