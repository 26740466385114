import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { PLATFORM_ID, Injectable, Inject } from '@angular/core';

@Injectable({ providedIn: "root" })
export class CookieService {
    private documentIsAccessible: boolean;
    constructor(
        @Inject(DOCUMENT) private document: Document,
        // Get the `PLATFORM_ID` so we can check if we're in a browser.
        @Inject(PLATFORM_ID) platformId: Object) {
        this.documentIsAccessible = isPlatformBrowser(platformId);
    }
    /**
     * @param name Cookie name
     * @returns boolean - whether cookie with specified name exists
     */
    public check(name: string): boolean {
        if (!this.documentIsAccessible) {
            return false;
        }
        name = encodeURIComponent(name);
        const regExp = this.getCookieRegExp(name);
        const exists = regExp.test(this.document.cookie);
        return exists;
    }
    /**
     * @param name Cookie name
     * @returns property value
     */
    public get(name: string): string {
        if (this.documentIsAccessible && this.check(name)) {
            name = encodeURIComponent(name);
            const regExp = this.getCookieRegExp(name);
            const result = regExp.exec(this.document.cookie);
            if (result) {
                return this.safeDecodeURIComponent(result[1]);
            }
            return null;
        }
        else {
            return null;
        }
    }

    /**
     * @param name Cookie name
     */
    public deleteCookie(name): void {
        this.setCookie(name, '', -1);
    }

    private setCookie(name: string, value: string, expireDays: number, path: string = '') {
        let d:Date = new Date();
        d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
        let expires:string = `expires=${d.toUTCString()}`;
        let cpath:string = path ? `; path=${path}` : '';
        document.cookie = `${name}=${value}; ${expires}${cpath}`;
    }

    private getCookieRegExp(name: string): RegExp {
        const escapedName = name.replace(/([\[\]\{\}\(\)\|\=\;\+\?\,\.\*\^\$])/gi, '\\$1');
        return new RegExp('(?:^' + escapedName + '|;\\s*' + escapedName + ')=(.*?)(?:;|$)', 'g');
    }

    private safeDecodeURIComponent(encodedURIComponent: string): string {
        try {
            return decodeURIComponent(encodedURIComponent);
        }
        catch (ex) {
            // probably it is not uri encoded. return as is
            return encodedURIComponent;
        }
    }
}