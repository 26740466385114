import { Injectable } from '@angular/core';

import { CompositeFilterDescriptor, State } from '@progress/kendo-data-query';
import { Observable, of, Subject, timer } from 'rxjs';
import { defaultIfEmpty, filter, map, switchMap, tap } from 'rxjs/operators';

import { IGridColumn, IGridSettings, ISettings } from 'src/app/models/grid';
import { IBaseColumn } from 'src/app/models/grid/base';
import { AuthorizationService, GridSettingsService } from '../api';
import { PersistingService } from '../shared';
import { DefaultSettingsProvider } from './interfaces';

const waitTimeForSync = 1000;

@Injectable()
export class SettingsPersistingService {
  private readonly settingKey: string;
  private readonly gridTypeID: string;
  private readonly $serverSync = new Subject<ISettings>();

  constructor(
    private readonly browserPersistingService: PersistingService,
    private readonly serverPersistingService: GridSettingsService,
    private readonly authorizationService: AuthorizationService,
    private readonly defaultSettingProvider: DefaultSettingsProvider) {
    const settingsKeys = this.defaultSettingProvider.getGridSettingsKeys();
    const token = this.authorizationService.getAuthorizationTokenValues();
    this.settingKey = `${settingsKeys.gridPersistingToken}_${token.personId}`;
    this.gridTypeID = settingsKeys.gridTypeID;

    this.$serverSync.pipe(
      switchMap(settings => {
        return timer(waitTimeForSync).pipe(
          tap(() => this.serverPersistingService.saveSettings(this.gridTypeID, settings).subscribe())
        )
      })
    ).subscribe();
  }

  isCustomSettingsExist(): boolean {
    return this.browserPersistingService.isExists(this.settingKey);
  }

  saveGridSettings(columns: IBaseColumn[], state: State): void {
    const gridSettings = {
      state: state,
      columns: columns
    };

    this.browserPersistingService.set(this.settingKey, gridSettings);
    this.$serverSync.next(gridSettings);
  }

  loadGridSettings(): Observable<IGridSettings> {
    return this.getSettings()
      .pipe(
        tap(settings => this.mapDateFilter(settings.state.filter)),
        defaultIfEmpty(null)
      );
  }

  deleteSettings(): void {
    this.browserPersistingService.delete(this.settingKey);
    this.serverPersistingService.deleteSettings(this.gridTypeID).subscribe();
  }

  private getSettings(): Observable<IGridSettings> {
      return this.serverPersistingService.getSettings(this.gridTypeID)
        .pipe(
          filter(item => item !== null),
          tap((settingsFromServer) => this.browserPersistingService.set(this.settingKey, settingsFromServer)),
          map((settings) => this.map(settings)),
        );
  }

  private map(settings: ISettings): IGridSettings {
    const defaultColumns = this.defaultSettingProvider.getGridColumnsConfig();
    if (settings.state.filter) {
      this.mapDateFilter(settings.state.filter);
    }

    return {
      state: settings.state,
      columns: this.mergeColumnSettings(settings.columns, defaultColumns)
    };
  }

  private mergeColumnSettings(columns: IBaseColumn[], defaultColumns: IGridColumn[]): IGridColumn[] {
    return columns.map((column) => {
      let defColumn = defaultColumns.find(c => c.field === column.field);

      return defColumn
        ? { ...defColumn, ...column }
        : <IGridColumn>{ ...column };
    });
  }

  private mapDateFilter(descriptor: CompositeFilterDescriptor) {
    const filters = descriptor?.filters || [];

    filters.forEach((filter: any) => {
      if (filter.filters) {
        this.mapDateFilter(filter);
      } else if (filter.field === 'FirstOrderedOn' && filter.value) {
        filter.value = new Date(filter.value);
      }
    });
  }
}
