import { IReview } from "./review.interface";

export class Review implements IReview {
    
    constructor(obj) {
        Object.assign(this, obj);
    }

    StudyName: string;
    CountryName: string;
    SiteName: string;
    SubjectName: string;
    VisitName: string;
    RaterName: string;
    FormName: string;
    AssessmentDate: Date;
    SubmittedDate: Date;
    ReviewPhaseID: string;
    ReviewStatusID: string;
    RoundNumber: number;
    Age: number;
    IsReviewInProgress: boolean;
    ActiveReviewerName: string;
    ActiveReviewerID: string;
    CanConductReview: boolean;
    CanViewReview: boolean;

    get AgeDays(): number {
        if (this.Age < 24) {
            return this.Age / 100.;
        }
        return Math.floor(this.Age / 12) / 2.;
    }

}