import { Component, OnInit } from '@angular/core';
import { BuildVersionModel } from '../models/buildVersion.model';
import { ApiInfoService } from '../services/api/api.info.service';
import { AuthorizationService } from '../services/api/authorization.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {

  public firstName: string;
  public lastName: string;
  public isDropdownOpened : boolean = false;
  public toggle : boolean = false;
  public buildVersion: BuildVersionModel;
  public buildVersionView: string;

  constructor(
    private readonly authorizationService: AuthorizationService,  private readonly apiinfoService: ApiInfoService) { }

  ngOnInit(): void {
    const tokenValues = this.authorizationService.getAuthorizationTokenValues();
    this.firstName = tokenValues.firstname;
    this.lastName = tokenValues.lastname;
    this.apiinfoService.getBuildVersion().subscribe((data:BuildVersionModel)=>{
      this.buildVersion = data;
      if(!(this.buildVersion.environmentName === 'Prod')){        
        this.buildVersionView = data.environmentName + ': ' + data.buildVersion;
      }
    });   
  }

  public logout(): void {
    this.authorizationService.logout();
  }

  public toggleButton(): void {
    this.isDropdownOpened = !this.isDropdownOpened;
  }

  public closeMenu() {
    this.isDropdownOpened = false;
  }
}
