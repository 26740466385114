/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

export function getBaseUrl() {
    return document.getElementsByTagName('base')[0].href;
}

function environmentSettings() {
    return environmentsSettings[getBaseUrl()];
}

const providers = [
    { provide: 'ENV_SETTINGS', useFactory: environmentSettings, deps: [] },
];

platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch(err => console.log(err));


const environmentsSettings = {
    "http://localhost:9005/": {
        authorizationUri: "http://localhost:9007/",
    },
    "https://reviewportalat.medavante.net/": {
        authorizationUri: "https://charon3at.medavante.net/",
    },
    "https://reviewportaldev.medavante.net/": {
        authorizationUri: "https://charon3dev.medavante.net/",
    },
    "https://reviewportalforms.medavante.net/": {
        authorizationUri: "https://charon3forms.medavante.net/",
    },
    "https://reviewportalft.medavante.net/": {
        authorizationUri: "https://charon3ft.medavante.net/",
    },
    "https://reviewportalpft.medavante.net/": {
        authorizationUri: "https://charon3pft.medavante.net/",
    },
    "https://reviewportal.medavante.net/": {
        authorizationUri: "https://charon3.medavante.net/",
    },
    "https://reviewportalqa.medavante.net/": {
        authorizationUri: "https://charon3qa.medavante.net/",
    },
    "https://reviewportalqa2.medavante.net/": {
        authorizationUri: "https://charon3qa2.medavante.net/",
    },
    "https://reviewportalqa3.medavante.net/": {
        authorizationUri: "https://charon3qa3.medavante.net/",
    },
    "https://reviewportalsandbox.medavante.net/": {
        authorizationUri: "https://charon3sandbox.medavante.net/",
    },
    "https://reviewportalstg.medavante.net/": {
        authorizationUri: "https://charon3stg.medavante.net/",
    },
    "https://reviewportaltrn.medavante.net/": {
        authorizationUri: "https://charon3trn.medavante.net/",
    },
    "https://reviewportaltest.medavante.net/": {
        authorizationUri: "https://charon3test.medavante.net/",
    },
    "https://reviewportaltest2.medavante.net/": {
        authorizationUri: "https://charon3test2.medavante.net/",
    },
}