import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { IGridColumn } from '../../models/grid';

@Injectable({
  providedIn: 'root'
})
export class GridCommunicationService {
  public resetSettings = new Subject<boolean>();
  public columnsInitiated =  new Subject<IGridColumn[]>();
  public columnsChanged = new Subject<IGridColumn[]>();
  public reloadAllData = new Subject<boolean>();
  public exportListToExcel = new Subject<boolean>();
}
