import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';

import { ISettings } from 'src/app/models/grid';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class GridSettingsService extends BaseApiService {
  public readonly gridSettingsApiUrl: string;

  constructor(protected http: HttpClient) {
      super(http);
      this.gridSettingsApiUrl = 'gridapi/api/grid-settings';
  }

  getSettings(gridTypeId: string): Observable<ISettings> {
      return this.http
          .get<ISettings[]>(`${this.gridSettingsApiUrl}/get/${gridTypeId}` )
          .pipe(
              this.showError('Failed to get grid settings', of<any>(null))
          );
  }

  saveSettings(gridTypeId: string, settings: ISettings): Observable<void> {
      const gridsettings = {
          gridTypeId: gridTypeId,
          gridSettings: JSON.stringify(settings)
      }

      return this.http
          .post<ISettings[]>(`${this.gridSettingsApiUrl}/upload`, gridsettings)
          .pipe(
              this.showError('Failed to add or edit grid settings', of<any>(null))
          );
  }

  deleteSettings(gridTypeId: string): Observable<void> {
      return this.http
          .delete(`${this.gridSettingsApiUrl}/delete/${gridTypeId}`)
          .pipe(
              this.showError('Failed to delete grid settings', of<any>(null))
          );
  }
}
