import { Component, ElementRef, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  encapsulation: ViewEncapsulation.None
})
export class TooltipComponent {
  @Input() popupAnchor: ElementRef;
  @Input() showPopup: boolean;
}
