import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthorizationService } from 'src/app/services/api/authorization.service';

@Injectable({ providedIn: "root" })
export class canAccessReviewPortalGuard implements CanActivate {
    constructor(
        private authorizationSvc: AuthorizationService) { }

    canActivate(): boolean {
        const token = this.authorizationSvc.getAuthorizationTokenValues();
        if (token) {
            if (token.canAccessReviewPortal) {
                return true;
            }
        }

        this.authorizationSvc.redirectToAuthorization();

        return false;
    }
}