export interface IReviewDetails {
    reviewId: string;
    study: string;
    site: string;
    visit: string;
    rater: string;
    subject: string;
    assessment: string;
    canViewReviewList: boolean;
    canViewReview: boolean;
    canConductReview: boolean;
    canContinueConductReview: boolean;
    isConductReviewInProcess: boolean;
    isReviewSubmitted: boolean;
    activeUserName: string;
}

export class ReviewDetails implements IReviewDetails {
    
    constructor(like: IReviewDetails) {
        this.reviewId = like.reviewId;
        this.study = like.study;
        this.site = like.site;
        this.visit = like.visit;
        this.rater = like.rater;
        this.subject = like.subject;
        this.assessment = like.assessment;
        this.canViewReviewList = like.canViewReviewList;
        this.canViewReview = like.canViewReview;
        this.canConductReview = like.canConductReview;
        this.canContinueConductReview = like.canContinueConductReview;
        this.isConductReviewInProcess = like.isConductReviewInProcess;
        this.isReviewSubmitted = like.isReviewSubmitted;
        this.activeUserName = like.activeUserName;
    }

    reviewId: string;
    study: string;
    site: string;
    visit: string;
    rater: string;
    subject: string;
    assessment: string;
    canViewReviewList: boolean;
    canViewReview: boolean;
    canConductReview: boolean;
    canContinueConductReview: boolean;
    isConductReviewInProcess: boolean;
    isReviewSubmitted: boolean;
    activeUserName: string;
}