import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { of, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { IFilterOption } from 'src/app/models/filter-option.interface';
import { Review } from 'src/app/models/review/review.model';

import { IReviewRequest, IReview } from '../../models/review';
import { BaseApiService } from './base-api.service';

@Injectable({
    providedIn: 'root'
})
export class ReviewSearchService extends BaseApiService {
    public ReloadAllData = new Subject<boolean>();
    public ExportListToExcel = new Subject<boolean>();
    
    public readonly reviewApiUrl: string;
    
    constructor(
        protected http: HttpClient) {
        super(http);
        this.reviewApiUrl = 'gridapi/api/review-search';
    }

    getReviews(request: IReviewRequest): Observable<IReview[]> {
        return this.http
            .post<IReview[]>(`${this.reviewApiUrl}/results`, request)
            .pipe(
                map(data => data.map((response: Partial<IReview>) => new Review(response))),
                this.showError('Failed to get reviews', of<any>(null))
            );
    }

    getFilterOptions(): Observable<IFilterOption[]> {
        return this.http
            .get<IFilterOption[]>(`${this.reviewApiUrl}/filter-options`)
            .pipe(
                this.showError('Failed to get filter options', of<any>(null))
            );
    }
}
