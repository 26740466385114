import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { SpinnerComponent, SpinnerRef } from 'src/app/shared/components/spinner';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private spinnerRef: InternalSpinnerRef = null;
  private config: OverlayConfig = new OverlayConfig({
    scrollStrategy: this.overlay.scrollStrategies.block(),
    positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
    backdropClass: 'spinner-backdrop',
    hasBackdrop: true
  });

  constructor(private overlay: Overlay) { }

  show(): SpinnerRef {
    if (this.spinnerRef) {
      this.spinnerRef.add();
    } else {
      const overlayRef = this.overlay.create(this.config);
      const portal = new ComponentPortal(SpinnerComponent);
      overlayRef.attach(portal);

      this.spinnerRef = new InternalSpinnerRef(overlayRef);
      this.spinnerRef.afterDisposed().subscribe(() => this.spinnerRef = null);
    }

    return this.spinnerRef;
  }
}


class InternalSpinnerRef implements SpinnerRef {
  private counter = 1;
  private _afterDisposed: Subject<void> = new Subject();

  constructor(private overlayRef: OverlayRef) { }

  hide() {
    this.counter--;
    if (this.counter === 0) {
      this.overlayRef.dispose();
      this._afterDisposed.next();
    }
  }

  add() {
    this.counter++;
  }

  afterDisposed(): Observable<void> {
    return this._afterDisposed.asObservable();
  }
}
