import { Injectable } from '@angular/core';
import { DateTimeService } from './date-time.service';

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key: any, value: any) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

@Injectable({
  providedIn: 'root'
})
export class PersistingService {

  constructor(private dateTimeService: DateTimeService) {
  }

  public get<T>(token: string): T {
    const settings = localStorage.getItem(token);

    const reviver = (key, value) => {
      if (typeof value === "string" && this.dateTimeService.isIsoDate(value)) {
        return this.dateTimeService.convertToDate(value);
      }
      return value;
    }
    
    return settings ? JSON.parse(settings, reviver) : settings;
  }

  public isExists(token: string): boolean {
    return !!localStorage.getItem(token);
  }

  public set<T>(token: string, state: T): void {
    localStorage.setItem(token, JSON.stringify(state, getCircularReplacer()));
  }

  public delete(token: string): void {
    localStorage.removeItem(token);
  }
}
