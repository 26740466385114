import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import jwt_decode from "jwt-decode";

import { BaseApiService } from './base-api.service';
import { CookieService } from '../shared';
import { IToken } from '../../models/token.interface';
import { Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { IEnvironmentSettings } from 'src/app/models/environment-settings.interface';

@Injectable({
    providedIn: 'root'
})
export class AuthorizationService extends BaseApiService {
    public readonly authorizationApiUrl: string;
    public readonly authorizationCookieName: string = "Medavante.Authorization.ReviewPortal";
    tokenSubscription = new Subscription();

    constructor(
        @Inject('ENV_SETTINGS') private environmentSettings: IEnvironmentSettings,
        private cookieSvc: CookieService,
        private router: Router,
        protected http: HttpClient) {
        super(http);
        this.authorizationApiUrl  = `api/authorization`
    }

    public redirectToAuthorization(): void {
        this.cookieSvc.deleteCookie(this.authorizationCookieName);
        const redirectUrl = encodeURIComponent(window.location.href);
        window.location.href = `${this.environmentSettings.authorizationUri}${this.authorizationApiUrl}/authorize/${document.location.host}?redirectUrl=${redirectUrl}`;
    }

    public getAuthorizationTokenValues(): IToken {
        const token = this.cookieSvc.get(this.authorizationCookieName);
        if (token) {
            const decodedToken = jwt_decode<IToken>(token);
            if (!this.isTokenExpired(decodedToken.exp)) {
                this.expirationCounter(decodedToken.exp * 1000 - Date.now())
                return decodedToken;
            }
            else {
                this.tokenSubscription.unsubscribe();
                this.logout();
            }
        }

        return null;
    }

    public logout(): void {
        this.cookieSvc.deleteCookie(this.authorizationCookieName);
        const redirectUrl = encodeURIComponent(window.location.href);
        window.location.href = `${this.environmentSettings.authorizationUri}${this.authorizationApiUrl}/logout/${document.location.host}?redirectUrl=${redirectUrl}`;
    }

    public redirectToNotAuthorized(): void {
        this.router.navigate(['/error/401']);
    }

    private isTokenExpired(validTo: number): boolean {
        return Math.floor(Date.now() / 1000) >= validTo;
    }

    private expirationCounter(timeout: number): void {
        this.tokenSubscription.unsubscribe();
        this.tokenSubscription = of(null).pipe(delay(timeout)).subscribe((expired) => {
            this.getAuthorizationTokenValues();
        });
    }
}
