import { Directive, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[outsideClick]'
})
export class OutsideClick {

    @Output() outsideClickEvent = new EventEmitter<boolean>();
    dropdownElement: HTMLElement;

    constructor(tooltip: ElementRef) {
        this.dropdownElement = tooltip.nativeElement;
    }

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement) {
        const outsudeClick = !this.dropdownElement.contains(targetElement);
        if (outsudeClick) {
            this.outsideClickEvent.emit(true);
        }
    }

}
