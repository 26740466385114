import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatDialogModule } from '@angular/material';
import { MaDialogService } from './ma-dialog.service';
import { DialogComponent } from './components/dialog/dialog.component';

import { FormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';

import { GridModule } from '@progress/kendo-angular-grid';
import { PopupModule } from '@progress/kendo-angular-popup';

import { ColumnsSelectorComponent, TooltipComponent, ChartLegendComponent } from './components';
import { OutsideClick, InsertionDirective } from './directives';
import { SpinnerComponent } from './components/spinner';
import { NavMenuComponent } from '../nav-menu/nav-menu.component';
import { RouterModule } from '@angular/router';
import { MultiCheckFilterComponent } from './components/filters/multicheck-filter.component';
import { ActionComponent } from '../components/action/action.component';

@NgModule({
  declarations: [
    TooltipComponent,
    ColumnsSelectorComponent,
    ChartLegendComponent,
    OutsideClick,
    InsertionDirective,
    SpinnerComponent,
    NavMenuComponent,
    MultiCheckFilterComponent,
    DialogComponent,
    ActionComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    GridModule,
    PopupModule,
    OverlayModule,
    RouterModule,
    MatDialogModule
  ],
  providers: [
    MaDialogService
  ],
  exports: [
    TooltipComponent,
    ColumnsSelectorComponent,
    ChartLegendComponent,
    OutsideClick,
    NavMenuComponent,
    MultiCheckFilterComponent,
    ActionComponent
  ],
  entryComponents: [
    DialogComponent,
    ActionComponent,
    SpinnerComponent
  ]
})
export class SharedModule { }
