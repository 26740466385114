import { Component } from '@angular/core';
import { BuildVersionModel } from '../models/buildVersion.model';
import { AuthorizationService } from '../services/api';
import { ApiInfoService } from '../services/api/api.info.service';

@Component({
  selector: 'about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent {
  public buildVersionInfo: BuildVersionModel;

  constructor(private readonly apiInfoService: ApiInfoService) { }

  ngOnInit(): void {
    this.apiInfoService.getBuildVersion().subscribe((data: BuildVersionModel) => {
      this.buildVersionInfo = data;
    });
  }

  canShowBuildVersionInHeader(): boolean {
    if (!!this.buildVersionInfo) {
      return !(this.buildVersionInfo.environmentName === 'Prod');
    }
    return false;
  }

  isBuildVersionLoaded(): boolean {
    return !!this.buildVersionInfo;
  }

  getHeaderBuildVersion(): string {
    return `${this.buildVersionInfo.environmentName}: ${this.buildVersionInfo.buildVersion}`;
  }

  getContentBuildVersion(): string {
      return this.buildVersionInfo.buildVersion;
  }
}
