import { Component, Injector, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ReviewDetails } from 'src/app/models/review/review-details.model';
import { ConductReviewService } from 'src/app/services/api';
import { DIALOG_DATA } from 'src/app/shared/components/dialog/dialog.component';
import { ActionSettings } from './action-settings';

@Component({
    selector: 'app-action',
    templateUrl: './action.component.html',
    styleUrls: ['./action.component.scss']
})
export class ActionComponent implements OnInit {
    @Input() staticMode: boolean = false;
    reviewInfo: ReviewDetails;
    public settings: ActionSettings | undefined;

    constructor(
        private injector : Injector,
        private route: ActivatedRoute,
        private reviewApiSvc: ConductReviewService
    ) {}

    ngOnInit(): void {
        if (!this.staticMode) {
            this.settings = this.injector.get(DIALOG_DATA);
        }

        if (!!this.settings) {
            this.reviewInfo = this.settings.reviewInfo || null;
        }

        if (!this.reviewInfo) {
            this.route.data.pipe(first()).subscribe(data => {
                this.reviewInfo = data['reviewInfo'];
            });
        }
    }

    public viewReview() {
        this.reviewApiSvc.navigateTo(`${location.origin}/review/#/${this.reviewInfo.reviewId}`);
    }

    public openReviewPortal(){
        const reviewPortalUrl = `${location.origin}/#/reviews`;
        this.reviewApiSvc.navigateTo(reviewPortalUrl);
    }

    public startReview(): void{
        this.reviewApiSvc.startReview(this.reviewInfo.reviewId).subscribe((data:any) => {
            this.viewReview();
        })
    }
}
