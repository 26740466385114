import { Injectable, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogRef } from './components/dialog/dialog-ref';
import { DialogInternalSettings, DialogSettings } from './components/dialog/dialog-settings';
import { DialogComponent } from './components/dialog/dialog.component';

const defaultDialogSettings: DialogSettings = {
    title: 'New Dialog',
    yesButtonText: 'Ok',
    cancelButtonText: 'Cancel',
    btnIsDefault: true,
    data: null,
    hasAddButton: true,
    hasCancelButton: true,
    hasCloseButton: true,
    contentClass: 'dialog-content',
    width: '520px',
    panelClass: '',
    noFooter: false
};

@Injectable({
    providedIn: 'root'
})
export class MaDialogService {
    constructor(private dialog: MatDialog) { }

    public openDialog(componentType: Type<any>, settings: Partial<DialogSettings> = null): DialogRef {
        const fullSettings = this.createSettings(componentType, settings);
        const dialogRef = this.dialog.open(DialogComponent, {
            panelClass: 'dialog-panel',
            width: fullSettings.width,
            maxWidth: fullSettings.maxWidth,
            data: fullSettings
        });
        return new DialogRef(dialogRef.componentInstance, dialogRef);
    }

    private createSettings(componentType: Type<any>, partialSettings: Partial<DialogSettings>): DialogInternalSettings {
        return Object.assign({}, defaultDialogSettings, partialSettings, { childComponent: componentType });
    }
}
