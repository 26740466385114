import { DialogComponent } from './dialog.component';
import { Observable } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogVisualSettings } from './dialog-settings';

export class DialogRef {
    constructor(private dialog: DialogComponent, private parentDialogRef: MatDialogRef<DialogComponent, any>) {
    }

    public close(result: any = null) {
        this.dialog.close(result);
    }

    public updateAddState(isEnabled: boolean) {
        this.dialog.isAddEnabled = isEnabled;
    }

    public updateSettings(settings: Partial<DialogVisualSettings>) {
        this.dialog.updateSettings(settings);
    }

    public overrideClose(closeFn: () => void) {
        this.dialog.customCloseFn = closeFn;
    }

    public overrideYes(yesFn: () => void) {
        this.dialog.customYesFn = yesFn;
    }

    public beforeAdd(): Observable<boolean> {
        return this.dialog.beforeAdd;
    }

    public afterClosed(): Observable<any> {
        return this.parentDialogRef.afterClosed();
    }
}
