import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { BuildVersionModel } from 'src/app/models/buildVersion.model';

import { BaseApiService } from './base-api.service';

@Injectable({
    providedIn: 'root'
})

export class ApiInfoService extends BaseApiService {
    private readonly ApiInfoUrl: string;

    constructor(
        protected http: HttpClient) {
        super(http);
        this.ApiInfoUrl = `gridapi/api/info`;
    }

    getBuildVersion(): Observable<BuildVersionModel>{
        return this.http.get<BuildVersionModel>(`${this.ApiInfoUrl}/build-version`)
            .pipe(this.showError('Failed to get build-version', of<any>(null)));
    }
}