import { Injectable, NgModule, } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS, HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map} from 'rxjs/operators';

import { AuthorizationService } from '../services/api';
import { CookieService, DateTimeService } from '../services/shared';
import { Router } from '@angular/router';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

    constructor(private cookieService: CookieService,
        private authorizationService: AuthorizationService,
        private router: Router) {
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = this.addAuthenticationToken(request);
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
            if (error instanceof HttpErrorResponse) {
                switch (error.status) {
                    case 401:
                        this.handleUnauthorizedError();
                        break;
                    case 403:
                        this.handleError(error.status);
                        break;
                }
            }

            return throwError(error);
        }));
    }

    private handleUnauthorizedError(): void {
        this.authorizationService.redirectToAuthorization();
    }

    private handleError(statusCode: number): void {
        this.router.navigate([`/error/${statusCode}`]);
    }

    private addAuthenticationToken(request: HttpRequest<any>) {
        var token = this.cookieService.get(this.authorizationService.authorizationCookieName);
        if (!token) {
            return request;
        }
        request = request.clone({
            withCredentials: false,
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });

        return request;
    }
}


@Injectable()
export class JsonIsoDateParseInterceptor implements HttpInterceptor {

    constructor(private dateTimeService: DateTimeService) {
    }
    
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse){
                    this.convertDatesRecursive(event.body);
                }
                return event;
            }));
    }

    private convertDatesRecursive(instance: any) : void {
        if (instance === null ||
            instance === undefined ||
            typeof instance !== 'object') {
                return;
        }
        for (const name of Object.keys(instance)) {
            const value = instance[name];
            if (typeof value === 'object') {
                this.convertDatesRecursive(value);
            }
            else if (this.dateTimeService.isIsoDate(value)) {
                instance[name] = this.dateTimeService.convertToDate(value);
            }
        }
    }
}

@NgModule({

    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizationInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JsonIsoDateParseInterceptor,
            multi: true
        }
    ]
})
export class InterceptorModule { }
