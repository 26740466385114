import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { IReviewDetails } from '../../models/review/review-details.model';

import { BaseApiService } from './base-api.service';

@Injectable({
    providedIn: 'root'
})

export class ConductReviewService extends BaseApiService {
    private readonly conductReviewApiUrl: string;

    constructor(
        protected http: HttpClient) {
        super(http);
        this.conductReviewApiUrl = `gridapi/api/conduct-review`;
    }

    getReviewDetails(reviewId: string): Observable<IReviewDetails> {
        const url = `${this.conductReviewApiUrl}/details/${reviewId}`;
        return this.http
            .get<IReviewDetails>(url, { headers: this.noCacheHeader })
            .pipe(this.showError('Failed to get review details', of(null)));
    }

    startReview(reviewId: string): Observable<void> {
        return this.http.post<void>(`${this.conductReviewApiUrl}/${reviewId}`, null)
            .pipe(this.showError('Failed to condact review', of<any>(null)));;
    }
}