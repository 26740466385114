import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AboutComponent } from "./about/about.component";
import { canAccessReviewPortalGuard } from './bases/guards/can-access-review-portal.guard';
import { ReviewInfoResolver } from "./bases/resolvers/review-info.resolver";
import { SelectModeComponent } from "./components/select-mode/select-mode/select-mode.component";
import { ErrorPageComponent } from "./error-page/error-page.component";

const routes: Routes = [
  { 
    path: '', 
    pathMatch: 'full', 
    redirectTo: 'reviews' 
  },
  {
    path: 'action-required/:reviewId', 
    component: SelectModeComponent,
    canActivate: [canAccessReviewPortalGuard],
    resolve: {
        reviewInfo: ReviewInfoResolver
    }
  },
  { 
    path: 'reviews',
    canActivate: [canAccessReviewPortalGuard],
    loadChildren: () => import('./reviews/reviews.module').then(m => m.ReviewsModule)
  },
  {
    path: 'error/:statusCode',
    canActivate: [canAccessReviewPortalGuard],
    component: ErrorPageComponent
  },
  {
    path:'about',
    canActivate: [canAccessReviewPortalGuard],
    component: AboutComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
